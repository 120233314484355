import React, { useContext, useEffect, useState } from 'react';
import { SageAPI } from '../api';
import { useApi } from 'contexts/api';
import { Outlet } from 'react-router-dom';

const StorageContext = React.createContext(undefined);

export function StorageProvider(props) {
	const { dwellerId } = props;
	const [ schema, setSchema ] = useState();
	const { sageAPI } = useApi();
	
	useEffect(() => {
		if (!sageAPI) return;
		if (!dwellerId) return;
		sageAPI.call(dwellerId, { action: 'getSchema' }).then(setSchema);
	}, [ sageAPI, dwellerId ]);

	return <StorageContext.Provider value={{ 
		storageDwellerId: dwellerId, 
		schema,
	}}>
		<Outlet/>
	</StorageContext.Provider>;
}

export function useStorage() {
	const { storageDwellerId, schema } = useContext(StorageContext);
	return { storageDwellerId, schema };
}
