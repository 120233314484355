import { Menu, theme, Layout } from 'antd';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import strings from 'strings';
import { useApi } from 'contexts/api';
import { useUser } from 'contexts/user';

import './style.scss';

const { Header } = Layout;




export const AppHeader = () => {
	const { user } = useUser();
	const { sageAPI } = useApi();
	const [ templates, setTemplates ] = useState([]);

	useEffect(() => {
		if (!sageAPI) return; // TODO: зависеть только от одного
		if (!user) return;
		sageAPI.call('content.templates').then(setTemplates);
	}, [ sageAPI, user ])

	const nav = [
		{
			key: 'system',
			label: 'System',
			children: [
				{ 
					key: 'templateParams',
					label: <Link to="/content/templateParams">Template params</Link>, 
				}, 
				{ 
					key: 'templates',
					label: <Link to="/content/templates">Templates</Link>, 
				}, 
			]
		},
		{
			key: 'game',
			label: 'Game',
			children: [
				{ 
					key: 'games',
					label: <Link to="/games">Games</Link>, 
				}, 
				{ 
					key: 'chats',
					label: <Link to="/chats">Chats</Link>, 
				}, 
				{ 
					key: 'chatMessages',
					label: <Link to="/chatMessages">Chat messages</Link>, 
				}, 
			]
		}
	];
	if (templates) {
		templates.forEach(template => {
			nav.push({
				key: `template.${template.id}`,
				label: <Link to={`/content/${template.id}`} >{template.values.title}</Link>,
			})
		})
	}
	
	return <Header style={{ backgroundColor: '#073039' }}>

			<Link className='header-logo' to='/'>
				<img className='header-logo-img' src='https://i.ibb.co/vcyK4kp/LOGO.png'/>
				Cloudshire
			</Link>
	
		<Menu
			theme='dark'
			style={{ backgroundColor: '#073039' }}
			mode="horizontal"
			defaultSelectedKeys={['2']}
			items={nav}
       	/>
	</Header>
}
