import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ChancellorChatPage } from './chat';
import { useApi } from 'contexts/api';
import { Tabs, Space, Card, Button } from 'antd';

import './style.scss'

function getTurnName(turn) {
	let year = Math.floor(turn / 4) + 1415; // TODO: константа из собранного контента
	const seasonNames = [
		'Spring',
		'Summer',
		'Autumn',
		'Winter',
	];
	let seasonIndex = turn % 4;
	let seasonName = seasonNames[seasonIndex];
	return `Year ${year}, ${seasonName}`;
}

const Chronicle = (props) => {
	const { chronicle, gameModel } = props;
	if (!chronicle) return <>Empty</>;
	let entries = [];
	for (let chronicleRecord of chronicle) {
		let text = chronicleRecord.values.content;
		if (chronicleRecord.values.type == 'decision') {
			let turnName = getTurnName(chronicleRecord.values.turn);
			text = `${turnName}. ${text}`;
		}
		entries.push(text)
	}
	return <Space direction='vertical'>
		<Card>{gameModel.values.cityDescription}</Card>
		{entries.map(text => <Card>{text}</Card>)}
	</Space>
}

export const GamePage = (props) => {
	const { gameId } = useParams();
	const { sageAPI } = useApi();
	const [ gameModel, setGameModel ] = useState();
	const [ chronicle, setChronicle ] = useState();

	const dwellerId = `play.games.${gameId}`;

	useEffect(() => {
		if (!sageAPI) return;
		if (!gameId) return;
		refresh();
	}, [ sageAPI, gameId ]);

	const refresh = () => {
		sageAPI.call(`${dwellerId}.chronicle`).then(setChronicle);
		sageAPI.call(dwellerId).then(setGameModel);
	}

	const next = () => {
		sageAPI.call(dwellerId, { action: 'next' }).then((res) => {
			console.log('RES')
			console.log(res)
			refresh()
		});
	}

	if (!gameModel) return <>NO GAME MODEL</>

	const items = [
	  {
	    key: 'chronicle',
	    label: 'Chronicle',
	    children: <>
	    	<Button onClick={next}>NEXT</Button>
	    	<Chronicle gameModel = {gameModel} chronicle={chronicle}/>
	    </>
	  },
	  {
	    key: 'chancellor',
	    label: 'Chancellor',
	    children: <ChancellorChatPage gameModel={gameModel} onMessageReceived={() => refresh()}/>,
	  },
	];
	return <div className='game-page'>
		<Tabs defaultActiveKey="1" items={items}/>
	</div>;
}