import { Select } from 'antd'; 
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useApi } from 'contexts/api';

const LinkRender = (props) => {
	const { sageAPI } = useApi();
	const [ schema, setSchema ] = useState();
	const [ models, setModels ] = useState();
	const [ options, setOptions] = useState([]);

	const getModelTitle = (model, schema) => {
		let out = `[${model.id}]`;
		if (schema && schema.objectTitleField) {
			out += ` ${model.values[schema.objectTitleField]}`;
		} 
		return out;
	}
	
	const storageId = props.field.type.storageId;

	useEffect(() => {
		if (!storageId) return;
		if (!sageAPI) return;
		sageAPI.call(storageId, { action: 'getSchema' }).then(setSchema);
		sageAPI.call(storageId).then(setModels);
	}, [ sageAPI, storageId ])

	useEffect(() => {
		if (!models) return;
		let opts = [{
			value: null,
			label: '---',
		}];
		for (let model of models) {
			opts.push({
				value: model.id,
				label: getModelTitle(model, schema),
			});
		}
		setOptions(opts);
	}, [ models, schema ])

	if (!models) return <></>;
	if (!props.onChange) {
		if (!props.defaultValue) return <></>;
		let selectedModel = models.find(model => model.id == props.defaultValue);
		if (!selectedModel) return <>Broken link {props.defaultValue}</>;
		return <>{getModelTitle(selectedModel, schema)}</>
		// Неизвестно какую ссылку генерить
		// return <Link to={`/templates/${templateId}/${props.defaultValue}`}> 
		// 	{getModelTitle(model, schema)}
		// </Link>;
	}

	return <Select
		showSearch
		style={{ width: 200 }}
		dropdownMatchSelectWidth={false}
		onChange={props.onChange}
		options={options}
		defaultValue={props.defaultValue}
		// filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
		// filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
	/>
}

export const LinkField = {
	render: LinkRender,
	filter: {
		eq: (value, filterValue) => value == filterValue,
		render: LinkRender,
	},
}


