import { Button, Select, Popover, Switch, Space } from 'antd';
import { PlusCircleOutlined, GroupOutlined } from '@ant-design/icons'
import { useState } from 'react';

const FieldSwitch = (props) => {
	const { onChange, defaultValue, field } = props;

	return <Space className='field-switch'>
		<Switch defaultChecked={!defaultValue} onChange={newValue => onChange(field.code, !newValue)}/>
		<div>{field.title}</div>
	</Space>
}

const FieldSelector = (props) => {
	let { defaultValue, fields, onChangeFieldVisibility } = props;

	return <Space direction='vertical'>
		{Object.values(fields).map(field => <FieldSwitch
			field={field}
			key={field.code}
			defaultValue={defaultValue[field.code]}
			onChange={onChangeFieldVisibility}
		/>)}
		<Button onClick={props.onApply} type='primary'>Apply</Button>
	</Space>
}

export const Footer = (props) => {
	const { defaultValue, onChangeHiddenFields, fields } = props;
	const [ fieldSelectorVisible, setFieldSelectorVisible ] = useState();
	const [ hiddenFields, setHiddenFields ] = useState(defaultValue);

	const onHiddenFieldsApply = () => {
		onChangeHiddenFields({ ...hiddenFields });
		setFieldSelectorVisible(false);
	}

	const onChangeHiddenField = (field, value) => {
		hiddenFields[field] = value;
	}

	return <div className='table-footer'>
		<Button className='action-button' icon={<PlusCircleOutlined />} onClick={props.onCreateNew}>Create new</Button>
		<div className='field-selector-container'>
			<Popover 
				open={fieldSelectorVisible} 
				content={<FieldSelector 
					fields={fields}
					defaultValue={hiddenFields}
					onChangeFieldVisibility={onChangeHiddenField}
					onApply={onHiddenFieldsApply}
				/>}
				trigger='click'
				onOpenChange={() => setFieldSelectorVisible(!fieldSelectorVisible)}
				title='Visible fields'
			>
	    <Button icon={<GroupOutlined/>} onClick={() => setFieldSelectorVisible(true)}>Visible fields</Button>
	 	 	</Popover>
 	 	</div>		
	</div>
}