import { StringField } from './string';
import { NumberField } from './number';
import { BoolField } from './bool';
import { LinkField } from './link';
import { JsonField } from './json';


export const getFieldDataForType = (type) => {
	if (type.name === 'string') return StringField;
	if (type.name === 'number') return NumberField;
	if (type.name === 'bool') return BoolField;
	if (type.name === 'link') return LinkField;
	if (type.name === 'json') return JsonField;
	// if (type.name === 'bool') return BoolField;
	// if (type.name === 'number') return BoolField;
	return StringField;
}