import { useEffect, useState } from 'react';
import { Route, Routes, useParams, useNavigate } from 'react-router-dom';
import { useApi } from 'contexts/api';
import { Table, Button } from 'antd'; 
import { HeaderCell } from './components/header';
import { Footer } from './components/footer';
import { StorageProvider, useStorage } from 'contexts/storage';
import { CreateModelPage, UpdateModelPage } from '../model';
import { useCookies } from 'react-cookie';

import { getFieldDataForType } from 'content/datatypes';

import './style.scss';

const getModelTitle = (model, schema) => {
	let out = `[${model.id}]`;
	if (schema && schema.objectTitleField) {
		out += ` ${model.values[schema.objectTitleField]}`;
	} 
	return out;
}

export const StoragePage = (props) => {
	const { storageDwellerId, schema } = useStorage();
	const { sageAPI } = useApi();

	const navigate = useNavigate();
	const [ visibleFields, setVisibleFields ] = useState([]);
	const [ models, setModels ] = useState();


	const [cookies, setCookie, removeCookie] = useCookies([ 
		`${storageDwellerId}.pageSize`, 
		`${storageDwellerId}.currentPage`,
		`${storageDwellerId}.filter`,
		`${storageDwellerId}.sorter`,
		`${storageDwellerId}.hiddenFields`,
	]);
	const [ filter, setFilter ] = useState({});
	const [ sorter, setSorter ] = useState({});
	const [ hiddenFields, setHiddenFields ] = useState({});
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);

	useEffect(() => {
		if (!storageDwellerId) return;
		if (!cookies) return;
		let currentPageCookies = cookies[`${storageDwellerId}.currentPage`];
		if (currentPageCookies) {
			setCurrentPage(parseInt(currentPageCookies));
		}
		let pageSizeCookies = cookies[`${storageDwellerId}.pageSize`]
		if (pageSizeCookies) {
			setPageSize(parseInt(pageSizeCookies));
		}
		let filterCookies = cookies[`${storageDwellerId}.filter`]
		if (filterCookies) {
			setFilter(filterCookies);
		}
		let sorterCookies = cookies[`${storageDwellerId}.sorter`]
		if (sorterCookies) {
			setSorter(sorterCookies);
		}
		let hiddenFieldsCookie = cookies[`${storageDwellerId}.hiddenFields`];
		if (hiddenFieldsCookie) {
			setHiddenFields(hiddenFieldsCookie);
		}
	}, [ storageDwellerId, cookies ]);

	const setFieldSorter = (fieldCode, value) => {
		if (value === undefined) {
			delete sorter[fieldCode];
		} else {
			sorter[fieldCode] = value;
		}
		let newSorter = { ...sorter };
		setCookie(`${storageDwellerId}.sorter`, newSorter, { path: '/' })
		setSorter(newSorter);
	}

	const setFieldFilter = (fieldCode, value) => {
		if (value === undefined) {
			delete filter[fieldCode];
		} else {
			filter[fieldCode] = value;
		}
		let newFilter = { ...filter };
		setCookie(`${storageDwellerId}.filter`, newFilter, { path: '/' })
		setFilter(newFilter);
	}

	useEffect(() => {
		if (!sageAPI) return;
		if (!storageDwellerId) return;
		sageAPI.call(storageDwellerId).then(setModels);
	}, [ sageAPI, storageDwellerId ]);

	useEffect(() => {
		if (!schema) return;
		let fields = schema.fields;
		if (!fields) return;
		setVisibleFields(Object.values(fields).map(field => ({
			field,
			visible: true,
		})));
	}, [ schema ])

	if (!models) return <>NO MODELS</>;
	if (!schema) return <>NO SCHEMA</>;
	if (!visibleFields) return <>NO SCHEMA</>;

	const onChangeHiddenFields = (value) => {
		let newHiddenFields = { ...value };
		setCookie(`${storageDwellerId}.hiddenFields`, newHiddenFields, { path: '/' })
		setVisibleFields(newHiddenFields);
	}

	const deleteModel = (model) => {
		if (!window.confirm(`Deleting ${getModelTitle(model, schema)}. Are you sure?`)) return;
		sageAPI.call(`${storageDwellerId}.${model.id}`, { action: 'delete' }).then(() => {
			sageAPI.call(storageDwellerId).then(setModels);
		});
	}

	let columns = [
		{
			title: <HeaderCell title='Actions'/>,
			width: '100px',
			render: (value, record) => {
				return <div>
					<Button onClick={() => deleteModel(record)}>DELETE</Button>
					<Button onClick={() => navigate(`${record.id}/clone`)}>CLONE</Button>
				</div>
			},
		},
		{
			title: <HeaderCell title='Id'/>,
			dataIndex: 'id',
		}
	];
	for (let field of Object.values(schema.fields)) {
		if (hiddenFields[field.code]) continue;
		let headerProps = {
			key: `${field.code}.header`,
			field,
			title: field.title,
		};
		let fieldData = getFieldDataForType(field.type);
		if (fieldData.filter) {
			headerProps.filter = {
				value: filter[field.code],
				onChange: (value) => setFieldFilter(field.code, value)
			}
		}
		if (fieldData.sorter) {
			headerProps.sorter = {
				value: sorter[field.code],
				onChange: (value) => setFieldSorter(field.code, value)
			}
		}
		let column = {
			title: <HeaderCell
				{...headerProps}
			/>,
			render: (value, record) => <fieldData.render
				defaultValue={record.values ? record.values[field.code] : undefined}
				field={field}
			/>
		}
		columns.push(column)
	}

	var data = [];
	models.forEach(model => data.push({
		key: model.id,
		id: model.id,
		...model,
	}));
	for (let [ fieldCode, filterValue ] of Object.entries(filter)) {
		let field = schema.fields[fieldCode];
		if (!field) continue;
		let fieldData = getFieldDataForType(field.type);
		let filterData = fieldData.filter;
		if (!filterData) continue;
		data = data.filter(model => filterData.eq(model.values[fieldCode], filterValue));
	};
	let sorts = Object.entries(sorter);
	if (sorts.length > 0) {
		data.sort((a, b) => {
			for (let [ fieldCode, sortOrder ] of sorts) {
				let field = schema.fields[fieldCode];
				if (!field) continue;
				let fieldData = getFieldDataForType(field.type);
				let sorter = fieldData.sorter;
				if (!sorter) continue;
				let res = sorter(a.values[fieldCode], b.values[fieldCode]) * sortOrder;
				if (res !== 0) {
					return res;
				}
			}
		})
	}


	var pagination = {
		current: currentPage,
		pageSize: pageSize,
		onChange: (current, pageSize) => {
			setCurrentPage(current);
			setCookie(`${storageDwellerId}.currentPage`, current, { path: '/' });
			setPageSize(pageSize);
			setCookie(`${storageDwellerId}.pageSize`, pageSize, { path: '/' });
		},
		showSizeChanger: true,
		total: data.length,
	};

	return <div>
		<Table
			bordered
			onRow={(record, rowIndex) => ({
				className: 'row-active', 
				onDoubleClick: () => { navigate(record.id) },
			})}
			className='plants-table'
			scroll={{
				x: 'max-content'
			}}
			title={() => <Footer 
				onChangeHiddenFields={onChangeHiddenFields}
				defaultValue={hiddenFields}
				fields={schema.fields}
				onCreateNew={() => navigate('create')}
			/>}
			dataSource={data} 
			pagination={pagination}
			columns={columns}
		/>
	</div>;
}