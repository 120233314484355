import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Input, Button, Form, Checkbox } from 'antd';
import { useCookies } from 'react-cookie';
import { useApi } from '../api';

import './style.scss';

const UserContext = React.createContext(undefined);

function Login(props) {
	const [ username, setUsername ] = useState();
	const [ password, setPassword ] = useState();

	const onLogin = () => {
		props.onLogin(username, password);
	}

	return <div className='login-container'>
		<div className='login-form'> 
			<div className='login-form-bg'/>
			<img className='login-logo' src='https://i.ibb.co/3rBcF1h/Logo2.png'/>
			<p className='login-title'>Cloudshire</p>
			<Input  placeholder='Username' className='username' onChange={e => setUsername(e.target.value)}/>
			<Input.Password placeholder='Password' className='password' onChange={e => setPassword(e.target.value)}/>
			<Button type='primary' onClick={onLogin} className='login-button'>Login</Button>
		</div>
	</div>
}

export function UserProvider(props) {
	const [ cookies, setCookie, removeCookie ] = useCookies([ 'session' ]);
	const [ user, setUser ] = useState();
	const { sageAPI } = useApi();

	const onLoginSuccess = (user) => {
		sageAPI.setSession(user.values.session);
		setUser(user);
	}

	useEffect(() => {
		if (!sageAPI) return;
		if (!cookies.session) return;
		if (user) return;
		sageAPI.call('users', { action: 'validateSession', session: cookies.session }).then(onLoginSuccess)
	}, [ sageAPI, cookies, user ])

	useEffect(() => {
		if (!user) return;
		if (!sageAPI) return;
		setCookie('session', user.values.session, {
			expires: new Date(Date.now() + 86400 * 7 * 1000),
			path: '/',
		});
	}, [ user, sageAPI ])

	const login = (username, password) => {
		sageAPI.call(`users.${username}`, { action: 'login', password }).then(onLoginSuccess)
	}



	if (!sageAPI) return <>No API</>
	if (!user) return <Login onLogin={login}/>;
	return <UserContext.Provider value={{ user }}>
		{props.children}
	</UserContext.Provider>;
}

export function useUser() {
	const { user } = useContext(UserContext);
	return { user };
}
