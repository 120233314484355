import React, { useContext, useEffect, useState } from 'react';
import { SageAPI } from '../api';

const ApiContext = React.createContext(undefined);

export function ApiProvider(props) {
	const [ sageAPI, setSageAPI ] = useState();

	useEffect(() => {
		const url = process.env.REACT_APP_API_URL;
		setSageAPI(new SageAPI({ url }));
	}, [])

	return <ApiContext.Provider value={{ sageAPI }}>
		{props.children}
	</ApiContext.Provider>;
}

export function useApi() {
	const { sageAPI } = useContext(ApiContext);
	return { sageAPI };
}
