import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApi } from 'contexts/api';
import { Button, Input } from 'antd'; 
import { notif } from 'components/notification';
import { useStorage } from 'contexts/storage';

export const CreateTemplatePage = (props) => {
	const navigate = useNavigate();
	const { sageAPI } = useApi();
	const [ templateId, setTemplateId ] = useState();
	const { storageDwellerId } = useStorage();

	const onSave = (newModel) => {
		if (!templateId) return;
		sageAPI.call(storageDwellerId, { action: 'createTemplate', templateId });
		navigate('..');
	}

	return <>
		<Input onChange={event => setTemplateId(event.target.value)} placeholder='Enter template ID'/>
		<Button onClick={onSave}>CREATE</Button>
	</>
}
