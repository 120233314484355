import { notif } from '../components/notification';

const makeRequest = (url, data) => {
	let request = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		}
	};
	if (data) {
		request.body = JSON.stringify(data);
	}
	return fetch(url, request).then((response) => {
		return response.json().then((res) => {
			if (res.status) {
				return res.data; // TODO: status, error
			} else {
				notif.error('API error', res.error);
				console.error('API REQUEST ERROR', request, res.error)
				throw new Error(res.error);
			}
		}, (err) => {
			notif.error('API error', 'No response from server');
		});
	});
};

export class SageAPI {
	commands = {};

	setSession(session) {
		this.session = session;
	}

	constructor(config) {
		if (!config) throw new Error('Error building SageAPI, no config provided!');
		if (!config.url) throw new Error('Error building SageAPI, no url provided!');
		this.config = config;
	}

	call(dweller, params = {}) {
		if (this.session) {
			params.session = this.session
		}
		return makeRequest(`${this.config.url}/${dweller}`, params);
	}
}
