import React, { useEffect, useState } from 'react';
import { Table, Button, theme, Space } from 'antd';
import { FilterOutlined } from '@ant-design/icons';
import { useHotkey } from 'contexts/hotkey';
import strings from 'strings';

import { getFieldDataForType } from 'content/datatypes';

import './style.scss';

const { Column } = Table;
const { useToken } = theme;

const HeaderSorter = (props) => {
	const [order, setOrder] = useState(props.value);

	useEffect(() => {
		setOrder(props.value);
	}, [props, props.value]);

	const toggle = (fieldCode) => {
		let newOrder;
		switch (order) {
			case 1:
				newOrder = -1;
				break;
			case -1:
				newOrder = undefined;
				break;
			default:
				newOrder = 1;
				break;
		}
		setOrder(newOrder);
		props.onChange && props.onChange(newOrder);
	};

	let sortName = 'Sort';
	if (order === 1) sortName = 'Sort A->Z';
	if (order === -1) sortName = 'Sort Z->A';

	return (
		<Button
			type={order ? 'primary' : 'default'}
			onClick={toggle}
		>
			{sortName}
		</Button>
	);
};

const HeaderFilter = ({ value, onChange, field }) => {
	const [opened, setOpened] = useState(false);
	const [filterValue, setFilterValue] = useState(value);
	const [inputValue, setInputValue] = useState(value);
	let filtered = filterValue !== undefined;

	const apply = () => {
		setOpened(false);
		setFilterValue(inputValue);
		onChange(inputValue);
	};

	const clear = () => {
		setInputValue(undefined);
		setFilterValue(undefined);
		onChange(undefined);
		setOpened(false);
	};

	useHotkey('Escape', () => {
		setOpened(false);
		setInputValue(filterValue);
	})

	let fieldData = getFieldDataForType(field.type);

	return <>
		{!opened && (
			<Button
				type={filtered ? 'primary' : 'default'}
				onClick={() => setOpened(true)}
			>
				{filtered ? (
					<div style={{ display: 'flex' }}>
						<fieldData.filter.render field={field} defaultValue={filterValue} />
						<FilterOutlined style={{ marginLeft: '8px', marginTop: 'auto', marginBottom: 'auto' }} />
					</div>
				) : (
					<div>
						Filter
						<FilterOutlined style={{ marginLeft: '8px', marginTop: '4px' }} />
					</div>
				)}
			</Button>
		)}
		{opened && (
			<div
				style={{
					width: '300px',
					display: 'flex',
				}}
			>
				<fieldData.filter.render
					isFilter
					key={field.code + value}
					field={field}
					defaultValue={value}
					onChange={setInputValue}
					onPressEnter={apply}
				/>
				<Button onClick={apply}>Apply</Button>
				<Button onClick={clear}>Clear</Button>
			</div>
		)}
	</>;
};

export const HeaderCell = ({ title, field, onChange, compact, filter, sorter }) => {
	return <div className='header-cell'>
		<div style={{ wordWrap: 'break-word', fontSize: '16px', height: '16px' }}>
			{title}
		</div>
		<div style={{ minHeight: '32px', marginTop: '10px', marginBottom: '10px', display: 'flex' }}>
			<Space>
			{sorter && <HeaderSorter 
				value={sorter.value} 
				onChange={sorter.onChange} 
				field={field} 
			/>}
			{filter && <HeaderFilter 
				value={filter.value} 
				onChange={filter.onChange} 
				field={field}
			/>}
			</Space>
		</div>
	</div>;
};

