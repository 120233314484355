import { useState, useEffect, useRef } from 'react';
import { Input, Button, Card } from 'antd';
import { useApi } from 'contexts/api'
import { SendOutlined } from '@ant-design/icons'


import './style.scss';

const { TextArea } = Input;



const BotTyping = () => {
	const [ dots, setDots ] = useState('...')

	useEffect(() => {
		let timeoutId = setTimeout(function() {
			if (dots.length === 3) {
				setDots('.');
			} else {
				setDots(dots + '.')
			}
		}, 400);
		return () => {
			clearTimeout(timeoutId)
		}
	}, [ dots ])

	return <div className='chat-typing-text'>Chancellor is thinking...{dots}</div>;
}

const Message = (props) => {
	let { message } = props;

	return <div className={`message ${message.role}`}>
		{/*<div className='message-avatar'></div>*/}
		{/*<div className='message-time'>{dayjs.unix(message.time / 1000).format('MMMM D, YYYY HH:mm')}</div>*/}
		<Card className='message-body'>
			{message.content}
		</Card>
	</div>
}

export const ChancellorChatPage = (props) => {

	const { gameModel } = props;
	
	const { sageAPI } = useApi();
	const [ messages, setMessages ] = useState([]);
	const [ currentMessage, setCurrentMessage ] = useState();
	const [ pendingMessage, setPendingMessage ] = useState();
	const messagesEndRef = useRef();


	useEffect(() => {
		if (!sageAPI) return;
		if (!gameModel) return;
		let chatId = gameModel.values.chancellorChatId;
		if (!chatId) return;
		sageAPI.call(`play.chats.${chatId}.messages`).then(res => {
			setMessages(res.map(msg => msg.values));
		});
	}, [ sageAPI ])

	useEffect(() => {
		let timeout = setTimeout(() => messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' }, 100));
		return () => clearTimeout(timeout);
	}, [ messages, pendingMessage ])

	const send = () => {
		setPendingMessage({
			role: 'user',
			content: currentMessage,
			time: Date.now(),
		});
		setCurrentMessage();
		sageAPI.call(`play.games.${gameModel.id}`, { 
			action: 'sayToChancellor',
			text: currentMessage,
		}).then(response => {
			if (props.onMessageReceived) {
				props.onMessageReceived();
			}
			setMessages([ ...messages, { role: 'user', content: currentMessage }, { role: 'assistant', content: response }])
			setPendingMessage();
		})
	}

	return <div className='chat-container' style={{ width: 700 }}>
		<div className='messages-container'>
			{messages.map((msg, index) => <Message key={index} message={msg}/>)}
			{pendingMessage && <Message message={pendingMessage}/>}
			{pendingMessage && <BotTyping/>}
			<div ref={messagesEndRef} />
		</div>
		<div className='chat-panel'>
			<Input.TextArea 
				onPressEnter={send}
				value={currentMessage}
				placeholder='Type your message' 
				onChange={e => setCurrentMessage(e.target.value)}
			/>
			<Button 
				onClick={send} 
				className='chat-send-button' 
				size='large' 
				icon={<SendOutlined/>} 
				type='primary'
			>
				Send
			</Button>
		</div>
	</div>
}