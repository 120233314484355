import { Input, TextArea } from 'antd'; 

const StringRender = (props) => {
	if (!props.onChange) {
		const style = {
			 maxWidth: 300,
			 textOverflow: 'ellipsis',
			 overflow: 'hidden',
			 whiteSpace: 'nowrap',
		};
		return <div style={style}>{props.defaultValue}</div>;
	}
	let componentProps = {
		autoFocus: props.isFilter,
		defaultValue: props.defaultValue,
		onChange: props.onChange ? e => props.onChange(e.target.value) : undefined,
	}
	// let RenderComponent = props.field.type.textArea ? Input.TextArea : Input;
	if (props.field.type.textArea) {
		return <Input.TextArea autoSize {...componentProps}/>;
	}
	return <Input {...componentProps} />;
}

export const StringField = {
	render: StringRender,
	filter: {
		eq: (value, filterValue) => {
			if (value === undefined) return false;
			if (filterValue === undefined) return true;
			return value.toLowerCase().includes(filterValue.toLowerCase());
		},
		render: StringRender,
	},
	sorter: (a, b) => {
		if (!a) a = '';
		if (!b) b = '';
		return a.localeCompare(b);
	},
}
