import { Input, TextArea } from 'antd'; 

const JsonRender = (props) => {
	const { defaultValue } = props;
	const parsedString = defaultValue ? JSON.stringify(defaultValue, null, 2) : '';
	if (!props.onChange) return <>{parsedString}</>;

	const onChange = (newValue) => {
		try {
			var parsed = JSON.parse(newValue);
		} catch (e) {
			return;
		}
		props.onChange(parsed);
	}

	let componentProps = {
		autoFocus: props.isFilter,
		defaultValue: parsedString,
	}
	if (props.onChange) {
		componentProps.onChange = (event) => onChange(event.target.value);
	}
	// let RenderComponent = props.field.type.textArea ? Input.TextArea : Input;
	return <Input.TextArea autoSize {...componentProps}/>;
}

export const JsonField = {
	render: JsonRender,
	filter: {
		eq: (value, filterValue) => {
			if (value === undefined) return false;
			if (filterValue === undefined) return true;
			return value.toLowerCase().includes(filterValue.toLowerCase());
		},
		render: JsonRender,
	},
	sorter: (a, b) => {
		if (!a) a = '';
		if (!b) b = '';
		return a.localeCompare(b);
	},
}
