import { InputNumber } from 'antd'; 

const NumberRender = (props) => {
	if (!props.onChange) return <>{props.defaultValue}</>;
	return <InputNumber
		autoFocus={props.isFilter}
		defaultValue={props.defaultValue}
		onChange={props.onChange}
	/>
}

export const NumberField = {
	render: NumberRender,
	filter: {
		eq: (value, filterValue) => {
			if (value === undefined) return false;
			if (filterValue === undefined) return true;
			return value.toLowerCase().includes(filterValue.toLowerCase());
		},
		render: NumberRender,
	},
	sorter: (a, b) => {
		if (!a) a = 0;
		if (!b) b = 0;
		return a < b;
	},
}
