import React from 'react-dom';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ConfigProvider, theme } from 'antd';

import { Layout, Menu } from 'antd';

import { ApiProvider } from './contexts/api';
import { HotkeyProvider } from './contexts/hotkey';
import { TemplateProvider } from './contexts/template';
import { StorageProvider } from './contexts/storage';
import { StoragePage } from 'pages/storage';
import { CreateTemplatePage } from 'pages/createTemplate';
import { CreateModelPage, UpdateModelPage, CloneModelPage } from 'pages/model';
import { GamePage } from 'pages/game';
import { UserProvider } from 'contexts/user';

import { AppHeader } from 'components/appHeader';

import strings from 'strings';

import './style.css';
import { mainColor } from 'theme';

const { Header, Content, Footer } = Layout;

strings.setLanguage('ru'); // TODO

export default function App() {
	return <ConfigProvider
		theme={{
			token: {
				colorText: '#073039',
				colorPrimary: '#c46e65',
				colorBgContainer: '#ecf6fd'
			}
		}}
	>
		<HotkeyProvider>
			<ApiProvider>
				<UserProvider>
					<BrowserRouter>	
						<Layout className='layout'>
							<AppHeader/>
							<Content >
								<Routes>
									<Route path="/content/:templateId" element={<TemplateProvider/>}>
										<Route path="create" element={<CreateModelPage/>}/>
										<Route path=":modelId/clone" element={<CloneModelPage/>}/>
										<Route path=":modelId" element={<UpdateModelPage/>}/>
										<Route path="" element={<StoragePage/>}/>
									</Route>
									<Route path="/content/templateParams" element={<StorageProvider dwellerId='content.templateParams'/>}>
										<Route path="create" element={<CreateModelPage/>}/>
										<Route path=":modelId/clone" element={<CloneModelPage/>}/>
										<Route path=":modelId" element={<UpdateModelPage/>}/>
										<Route path="" element={<StoragePage/>}/>
									</Route>
									<Route path="/content/templates" element={<StorageProvider dwellerId='content.templates'/>}>
										<Route path="create" element={<CreateTemplatePage/>}/>
										<Route path=":modelId/clone" element={<CloneModelPage/>}/>
										<Route path=":modelId" element={<UpdateModelPage/>}/>
										<Route path="" element={<StoragePage/>}/>
									</Route>
									<Route path="/players" element={<StorageProvider dwellerId='play.players'/>}>
										<Route path="create" element={<CreateModelPage/>}/>
										<Route path=":modelId/clone" element={<CloneModelPage/>}/>
										<Route path=":modelId" element={<UpdateModelPage/>}/>
										<Route path="" element={<StoragePage/>}/>
									</Route>
									<Route path="/chats" element={<StorageProvider dwellerId='play.chats'/>}>
										<Route path="create" element={<CreateModelPage/>}/>
										<Route path=":modelId/clone" element={<CloneModelPage/>}/>
										<Route path=":modelId" element={<UpdateModelPage/>}/>
										<Route path="" element={<StoragePage/>}/>
									</Route>
									<Route path="/chatMessages" element={<StorageProvider dwellerId='play.chatMessages'/>}>
										<Route path="create" element={<CreateModelPage/>}/>
										<Route path=":modelId/clone" element={<CloneModelPage/>}/>
										<Route path=":modelId" element={<UpdateModelPage/>}/>
										<Route path="" element={<StoragePage/>}/>
									</Route>
									<Route path="/games" element={<StorageProvider dwellerId='play.games'/>}>
										<Route path="create" element={<CreateModelPage/>}/>
										<Route path=":modelId/clone" element={<CloneModelPage/>}/>
										<Route path=":modelId" element={<UpdateModelPage/>}/>
										<Route path="" element={<StoragePage/>}/>
									</Route>
									<Route path="/gameChronicles" element={<StorageProvider dwellerId='play.gameChronicles'/>}>
										<Route path="create" element={<CreateModelPage/>}/>
										<Route path=":modelId/clone" element={<CloneModelPage/>}/>
										<Route path=":modelId" element={<UpdateModelPage/>}/>
										<Route path="" element={<StoragePage/>}/>
									</Route>
									<Route path="/users" element={<StorageProvider dwellerId='users'/>}>
										<Route path="create" element={<CreateModelPage/>}/>
										<Route path=":modelId/clone" element={<CloneModelPage/>}/>
										<Route path=":modelId" element={<UpdateModelPage/>}/>
										<Route path="" element={<StoragePage/>}/>
									</Route>
									<Route path="/apiLogs" element={<StorageProvider dwellerId='apiLogs'/>}>
										<Route path="create" element={<CreateModelPage/>}/>
										<Route path=":modelId/clone" element={<CloneModelPage/>}/>
										<Route path=":modelId" element={<UpdateModelPage/>}/>
										<Route path="" element={<StoragePage/>}/>
									</Route>
									<Route path="/play/:gameId" element={<GamePage/>}/>
								</Routes>
							</Content>
						</Layout>
					</BrowserRouter>
				</UserProvider>
			</ApiProvider>
		</HotkeyProvider>
	</ConfigProvider>;
}
