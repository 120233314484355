import { Switch } from 'antd'; 

const BoolRender = (props) => {
	if (!props.onChange) return <>{props.defaultValue ? 'True' : 'False'}</>;
	return <Switch
		autoFocus={props.isFilter}
		defaultValue={props.defaultValue}
		onChange={props.onChange}
	/>
}

export const BoolField = {
	render: BoolRender,
	filter: {
		eq: (value, filterValue) => value == filterValue,
		render: BoolRender,
	},
	sorter: (a, b) => {
		if (!a && b) return true;
		return false;
	},
}
