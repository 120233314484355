import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useApi } from 'contexts/api';
import { Table, Button } from 'antd'; 
import { getFieldDataForType } from 'content/datatypes';
import { notif } from 'components/notification';
import { useStorage } from 'contexts/storage';

import './style.scss'

export const CreateModelPage = (props) => {
	const navigate = useNavigate();
	const { sageAPI } = useApi();
	const { storageDwellerId } = useStorage();

	const onSave = (newModel) => {
		sageAPI.call(storageDwellerId, { action: 'create', values: newModel.values });
		navigate('..');
	}

	return <ModelPage 
		model={{ values: {} }}
		onSave={onSave}
	/>
}

export const UpdateModelPage = (props) => {
	const navigate = useNavigate();
	const { storageDwellerId } = useStorage();
	const { modelId } = useParams();
	const [ model, setModel ] = useState();
	const { sageAPI } = useApi();
	const modelDwellerId = `${storageDwellerId}.${modelId}`;
	
	useEffect(() => {
		if (!sageAPI) return;
		sageAPI.call(`${modelDwellerId}`).then(setModel);
	}, [ sageAPI ]);

	const onSave = (newModel) => {
		sageAPI.call(modelDwellerId, { action: 'update', values: newModel.values })
		navigate('..');
	}

	if (!model) return <>NO MODEL</>;

	return <ModelPage 
		model={model}
		onSave={onSave}
	/>
}


export const CloneModelPage = (props) => {
	const navigate = useNavigate();
	const { storageDwellerId } = useStorage();
	const { modelId } = useParams();
	const [ model, setModel ] = useState();
	const { sageAPI } = useApi();
	const modelDwellerId = `${storageDwellerId}.${modelId}`;
	
	useEffect(() => {
		if (!sageAPI) return;
		sageAPI.call(modelDwellerId).then(receivedModel => {
			delete receivedModel.id;
			setModel(receivedModel);
		});
	}, [ sageAPI ]);

	const onSave = (newModel) => {
		sageAPI.call(storageDwellerId, { action: 'create', values: newModel.values });
		navigate('..');
	}

	if (!model) return <>NO MODEL</>;

	return <ModelPage 
		model={model}
		onSave={onSave}
	/>
}

export const ModelPage = (props) => {
	const { model } = props;
	const { storageDwellerId, schema } = useStorage();
	const { sageAPI } = useApi();


	if (!schema) return <>NO SCHEMA</>;
	if (!model) return <>NO MODEL</>;

	const onChangeField = (fieldId, value) => {
		model.values[fieldId] = value;
	}

	let columns = [
		{ 
			title: 'Field',
			render: (value, record) => <div>{record.field.title}</div>,
		},
		{
			title: 'Value',
			dataIndex: 'value',
			render: (value, record) => {
				let fieldData = getFieldDataForType(record.field.type)
				return <fieldData.render
					defaultValue={value}
					field={record.field}
					onChange={newValue => onChangeField(record.field.code, newValue)}
				/>
			}
		}
	];

	const data = [];
	const fields = schema.fields;
	const values = model.values ?? {};
	if (fields) {
		for (let field of Object.values(fields)) {
			data.push({
				key: field.code,
				field,
				value: values[field.code],
			})
		}
	}

	return <div>
		<Table
			bordered
			onRow={(record, rowIndex) => ({
				className: 'row-active', 
			})}
			className='plants-table'
			scroll={{
				x: 'max-content'
			}}
			dataSource={data} 
			columns={columns}
			pagination={false}
		/>
		{props.onSave && <Button onClick={() => props.onSave(model)}>SAVE</Button>}
	</div>;
}