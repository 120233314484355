import { StorageProvider } from './storage';
import { useParams, Outlet } from 'react-router-dom';

export function TemplateProvider(props) {
	const { templateId } = useParams();
	const dwellerId = `content.templates.${templateId}.objects`;

	return <StorageProvider dwellerId={dwellerId}>
		<Outlet/>
	</StorageProvider>;
}
